import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "./Polymatech.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Container } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Navbar from "../../components/navbar/index";
import Footer from "../../components/Footer/Footer";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { useState, useEffect } from "react";
import Graph from "./Graph";
import { useLocation, useSearchParams,Link } from "react-router-dom";
import { api,imageApi,redirect,downloadapi } from "../../components/Api/Api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));
const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "white" : "white",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function Polymatech({ heading, img }) {
  const [data, setData] = useState([]);
  const [profit, setProfit] = useState([]);
  const [financial, setFinancial] = useState([]);
  const [projection, setProjection] = useState([]);
  const [about, setAbout] = useState([]);
  const [overview, setOverview] = useState([]);
  const [pdm, setPdm] = useState([]);
  const [promoters,setPromoters] = useState([])
  const [pam, setPam] = useState([]);
  const [faq,setFaq] = useState([])
  const [age, setAge] = useState("");
  const [expanded, setExpanded] = useState("panel1");
  const location = useLocation();
  const { pathname } = useLocation();

  const idC = location.state



  // Automatically scrolls to top whenever pathname changes
  const [searchParams] = useSearchParams();
  // console.log("search",searchParams);
  const id = searchParams.get("id");
  // const id = 3;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);


  console.log("ID", id)


  // useEffect(() => {
  //   const searchParams = new URLSearchParams(location.search);
  //   const idParam = searchParams.get("id");
  //   setIds(idParam);
  // }, [location.search]);

  // console.log("ids in polymatech",ids)



  

  const fetchData = async () => {
    try{
    await fetch(`${api}${id}`)
      .then((res) => res.json())
      .then((res) => {
        // console.log(res.detail.basicDetails.about)
        console.log("response",res)
        setData(res.detail);
      });
    }
    catch(error){
      console.log(error)
      if (error){
      toast.error("Error Fetching Data !");
      }
    }
  };

  
  // useEffect(() => {
  //   if (id) {
  //     fetchData();
  //   }
  // }, [id]);

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);








  const fetchCompanyData = async () => {
    try{
    await fetch(`${api}/${idC}`)
      .then((res) => res.json())
      .then((res) => {
        // console.log(res.detail.basicDetails.about)
        console.log("response",res)
        setData(res.detail);
      });
    }
    catch(error){
      console.log(error)
      if (error){
      toast.error("Error Fetching Data !");
      }
    }
  };
  useEffect(() => {
    if (idC) {
      fetchCompanyData();
    }
  }, [idC]);





  
  



  useEffect(() => {
    if (data && data.promoters && data.promoters.rows) {
      setPromoters(data.promoters.rows);
    }
  }, [data]);

  useEffect(() => {
    if (data && data.companyFaq && data.companyFaq.rows) {
      setFaq(data.companyFaq.rows);
    }
  }, [data]);

  useEffect(() => {
    if (data && data.ProfitLoss && data.ProfitLoss.rows) {
      setProfit(data.ProfitLoss.rows);
    }
  }, [data]);

  useEffect(() => {
    if (data && data.RatioAnalysis) {
      setFinancial(data.RatioAnalysis.rows);
    }
  }, [data]);

  useEffect(() => {
    if (data && data.basicDetais) {
      setPam(data.basicDetais);
    }
  }, [data]);

  useEffect(() => {
    if (data && data.projection) {
      setProjection(data.projection.rows);
    }
  }, [data]);

  useEffect(() => {
    if (data && data.basicDetails && data.basicDetails) {
      setAbout(data.basicDetails.about);
      setOverview(data.basicDetails.overview);
      setPdm(data.basicDetails.promotersDirectorsManagment);
    }
  }, [data]);

  const handleChange1 = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const ProjectionHandle = (status)=>{
    if(status === "P"){
      return "Projected"
    } else if(status === "A"){
      return "Actual"
    }
  }

  
  return (
    <>
      <Navbar />
      <ToastContainer />
      <Box sx={{ display: "flex", padding: "3em 0em 0em 1em" }}>
        <img src={`${imageApi}${pam.cmpImg}`} style={{ width: "10rem", height: "3rem",objectFit:"contain" }} alt={pam.cmpName } />
        <Typography
          variant="p"
          sx={{
            margin: "0.5em 0em 0em 1em",
            fontFamily: "Daikon-regular",
            fontSize: "x-large",
          }}
        >
          {" "}
          {pam.cmpName }
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          textAlign: "center",
          justifyContent: "center",
          margin: "0.5rem 2rem 0.5rem 2rem",
        }}
      >
        <Box sx={{ width: "55rem" }}>
          {/* <Graph name={id}/> */}
          <Graph name={id || idC} />
        </Box>
        <Card
          sx={{
            width: "25rem",
            height: "25rem",
            border: "1px solid darkgray",
            borderRadius: "10px",
            margin: "1rem 1rem 1rem 1rem",
          }}
        >
          <CardContent>
            <Box sx={{ display: "flex", fontFamily: "Daikon-regular" }}>
              <Typography gutterBottom variant="h6" component="div">
                Buy
              </Typography>
              <Typography gutterBottom variant="h6" component="div">
                Sell
              </Typography>
            </Box>
            <hr />
            <Box sx={{ paddingTop: "1rem" }}>
              <Typography
                variant="p"
                sx={{ fontWeight: 600, fontFamily: "Daikon-regular" }}
              >
                {pam.cmpName } Private Equity
              </Typography>
            </Box>
            <Box
              sx={{
                margin: "1.5rem 0rem 2rem 1rem",
                fontFamily: "Daikon-regular",
              }}
            >
              <TextField
                id="outlined-basic"
                label="Quantity"
                variant="outlined"
                type="number"
                sx={{ width: "20rem", height: "0.5rem" }}
              />
            </Box>
            <br />
            <Box
              sx={{
                margin: "1rem 0rem 2rem 1rem",
                fontFamily: "Daikon-regular",
              }}
            >
              <TextField
                id="outlined-basic"
                label="Message"
                variant="outlined"
                type="text"
                sx={{ width: "20rem", height: "2rem" }}
              />
            </Box>
            <Link to={redirect} target="_blank">
            <Button
              variant="contained"
              color="success"
              sx={{
                width: "20rem",
                backgroundColor: "green",
                margin: "1rem 0rem 0rem 0.8rem",
              }}
            >
              Buy
            </Button></Link>
          </CardContent>
        </Card>
      </Box>

      <Container>
        <Typography
          variant="h6"
          sx={{ margin: "5rem 1rem 1rem 1rem", fontFamily: "Daikon-regular" }}
        >
          About {heading.toUpperCase()}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            margin: "1rem 1rem 1rem 1rem",
          }}
        >
          <Typography variant="p" style={{ fontFamily: "Daikon-regular" }}>
            {pam.about}
          </Typography>
          <Typography
            variant="p"
            style={{
              fontFamily: "Daikon-regular",
              margin: "0.5rem 0rem 0rem 0rem",
            }}
          >
            {overview}
          </Typography>{" "}
        </Box>
      </Container>
      <Container maxWidth="lg" sx={{ padding: "5rem" }}>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ margin: "0rem 2rem 0rem 0rem" }}>
            <Typography
              sx={{
                fontSize: "x-large",
                fontWeight: 600,
                textAlign: "center",
                fontFamily: "Daikon-regular",
              }}
            >
              Promoters Or Management
            </Typography>
            <TableContainer component={Paper} sx={{ width: 400,marginTop:"2%" }}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{ fontWeight: 600, fontFamily: "Daikon-regular" }}
                    >
                      Name
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ fontWeight: 600, fontFamily: "Daikon-regular" }}
                    >
                      Designation
                    </TableCell>
                    {/* <TableCell
                      align="center"
                      sx={{ fontWeight: 600, fontFamily: "Daikon-regular" }}
                    >
                      Experience
                    </TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                    {promoters.map((name)=>{
                      return <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ fontFamily: "Daikon-regular" }}
                      >
                        {name.name}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ fontFamily: "Daikon-regular" }}
                      >
                        {name.designation}
                      </TableCell>
                      {/* <TableCell
                        align="center"
                        sx={{ fontFamily: "Daikon-regular" }}
                      >
                        30+
                      </TableCell> */}
                    </TableRow>
                    })}
                 
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box
            sx={{
              width: "40rem",
              backgroundColor: "aliceblue",
              position: "relative",
              borderRadius: "15px",
              fontFamily: "Daikon-regular",
              margin: "0rem 0rem 0rem 7rem",
            }}
          >
            <Typography
              sx={{ padding: "1rem 0rem 0rem 2rem", fontWeight: 600 }}
            >
              P &amp; L Statement
            </Typography>
            <TableContainer
              component={Paper}
              sx={{
                width: "35rem",
                margin: "1rem 2rem 2rem 2rem",
              }}
            >
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{ fontWeight: 600, fontFamily: "daikon-regular" }}
                    >
                      Id{" "}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ fontWeight: 600, fontFamily: "daikon-regular" }}
                    >
                      Revenue
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ fontWeight: 600, fontFamily: "daikon-regular" }}
                    >
                      Gross Profit
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ fontWeight: 600, fontFamily: "daikon-regular" }}
                    >
                      Operating Expenses
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ fontWeight: 600, fontFamily: "daikon-regular" }}
                    >
                      Interest Expenses
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ height: "1rem" }}>
                  {profit.length > 0
                    ? profit.map((row) => (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.id}
                          </TableCell>
                          <TableCell align="center">{row.revenue}</TableCell>
                          <TableCell align="right">{row.grossProfit}</TableCell>
                          <TableCell align="right">
                            {row.operatingExpenses}
                          </TableCell>
                          <TableCell align="right">
                            {row.interestExpenses}
                          </TableCell>
                        </TableRow>
                      ))
                    : ""}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Container>
      <Container maxWidth="lg" sx={{ display: "flex",width:"100%" }}>
        <TableContainer
          component={Paper}
          sx={{ margin: "0rem 2rem 2rem 2rem", border: "1px solid" }}
        >
          <Typography sx={{ fontWeight: 900, margin: "0.5rem 0rem 0rem 3rem" }}>
            Projection
          </Typography>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{ fontWeight: 600, fontFamily: "daikon-regular" }}
                >
                  Year
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ fontWeight: 600, fontFamily: "daikon-regular" }}
                >
                  Quarter
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ fontWeight: 600, fontFamily: "daikon-regular" }}
                >
                  Expenses
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ fontWeight: 600, fontFamily: "daikon-regular" }}
                >
                  Operating Profit
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ fontWeight: 600, fontFamily: "daikon-regular" }}
                >
                  Expenses
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ fontWeight: 600, fontFamily: "daikon-regular" }}
                >
                  Sales
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ fontWeight: 600, fontFamily: "daikon-regular" }}
                >
                  Projection Type
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projection.length > 0
                ? projection.map((m) => (
                    <TableRow
                      key={m.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ fontFamily: "Daikon-regular" }}
                      >
                        {m.year}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ fontFamily: "Daikon-regular" }}
                      >
                        {m.quarter}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ fontFamily: "Daikon-regular" }}
                      >
                        {m.expenses}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ fontFamily: "Daikon-regular" }}
                      >
                        {m.operatingProfit}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ fontFamily: "Daikon-regular" }}
                      >
                        {m.expenses}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ fontFamily: "Daikon-regular" }}
                      >
                        {m.sales}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ fontFamily: "Daikon-regular",
                         }}
                      >
                        {ProjectionHandle(m.projectionType)}
                      </TableCell>
                    </TableRow>
                  ))
                : ""}
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer
          component={Paper}
          sx={{ margin: "0rem 2rem 2rem 2rem", border: "1px solid" }}
        >
          <Typography
            sx={{ fontWeight: 900, margin: "0.5rem 0rem 0.5rem 3rem" }}
          >
            Ratio Analysis
          </Typography>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{ fontWeight: 600, fontFamily: "daikon-regular" }}
                >
                  Year
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ fontWeight: 600, fontFamily: "daikon-regular" }}
                >
                  Current Liquidity Ratio
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ fontWeight: 600, fontFamily: "daikon-regular" }}
                >
                  Quick Liquidity Ratio
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ fontWeight: 600, fontFamily: "daikon-regular" }}
                >
                  Gross Profit Margin
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {financial.length > 0
                ? financial.map((m) => (
                    <TableRow
                      key={m.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ fontFamily: "Daikon-regular" }}
                      >
                        {m.year}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ fontFamily: "Daikon-regular" }}
                      >
                        {m.currentLiquidityRatio}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ fontFamily: "Daikon-regular" }}
                      >
                        {m.quickLiquidityRatio}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ fontFamily: "Daikon-regular" }}
                      >
                        {m.grossProfitMargin}
                      </TableCell>
                    </TableRow>
                  ))
                : ""}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>

      <Container maxWidth="lg">
        <Typography
          variant="h6"
          sx={{
            fontSize: "x-large",
            fontWeight: 600,
            textAlign: "center",
            fontFamily: "Daikon-regular",
          }}
        >
          Download Financial Result
        </Typography>
        <Box
          sx={{
            width: "50rem",
            height: "6rem",
            backgroundColor: "aliceblue",
            margin: "1rem 2rem 2rem 11rem",
          }}
        >
          {/* <Box><Typography sx={{
              m: 1,
              minWidth: 250,
              // backgroundColor: "aliceblue",
              margin: "1.5rem 0rem 0rem 2rem",
              display:"flex",
              justifyContent:"center",
              textAlign:"center"
            }}>
              Financial Result
            </Typography></Box> */}
          {/* <FormControl
            sx={{
              m: 1,
              minWidth: 250,
              backgroundColor: "white",
              margin: "1.5rem 0rem 0rem 2rem",
            }}
            size="small"
          >
            <Select
              value={age}
              onChange={handleChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="">
                <em>Financial Result</em>
              </MenuItem>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl> */}
          <FormControl
            sx={{
              m: 1,
              minWidth: 250,
              backgroundColor: "white",
              margin: "1.5rem 0rem 0rem 10rem",
            }}
            size="small"
          >
            <Select
              value={age}
              onChange={handleChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="">
                <em>Select Year</em>
              </MenuItem>
              <MenuItem value={2021}>2021</MenuItem>
              <MenuItem value={2022}>2022</MenuItem>
              <MenuItem value={2023}>2023</MenuItem>
            </Select>
          </FormControl>
          <a
    href={`http://192.168.1.47:8080/api/V1/download/balancesheet/${id}?year=${age}`}
    target="_blank"
    rel="noopener noreferrer"
  >
          <Button
            variant="contained"
            color="success"
            sx={{
              width: "10rem",
              margin: "1.5rem 2rem 0rem 2rem",
              backgroundColor: "green",
              
            }}

          >
           Download
          </Button></a>
        </Box>
      </Container>

      <Container maxWidth="lg">
        <Typography
          sx={{
            textAlign: "center",
            fontWeight: 600,
            fontSize: "x-large",
            fontFamily: "Daikon-regular",
            paddingTop: "1rem",
          }}
        >
          Frequently Asked Questions
        </Typography>
        <Typography
          variant="p"
          sx={{ textAlign: "center", fontFamily: "Daikon-regular" }}
        >
          {" "}
          <p>
            {" "}
            Find Answers to common questions that you may have in your mind{" "}
          </p>
        </Typography>
        <Box sx={{ width: "70rem", padding: "2rem" }}>
          {faq && faq.map((faq)=>{
            return <Accordion
            key={faq.id}
            expanded={expanded === `panel${faq.id}`}
            onChange={handleChange1(`panel${faq.id}`)}
            // expanded={expanded === "panel1"}
            // onChange={handleChange1("panel1")}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography sx={{ fontFamily: "Daikon-regular" }}>
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ fontFamily: "Daikon-regular" }}>
                {faq.ans}
              </Typography>
            </AccordionDetails>
          </Accordion>
          })}         
        </Box>
      </Container>
      <Footer />
    </>
  );
}
