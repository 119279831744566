import React from "react"
import Navbar from "../../components/navbar/index";
import Footer from "../../components/Footer/Footer";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import business from "../../assets/images/business.jpg";
import './Common.css'

export default function Business(){
    return(
        <>
        <Navbar/>
        <Box sx={{ padding: "2rem 0rem 0rem 0rem" }}>
        <img
          style={{
            width: "100%",
            justifyContent: "center",
            margin: "auto",
            display: "block",
            height: "20rem",
          }}
          src={business}
          alt="business"
        />
      </Box>
      <Typography
        variant="h5"
        sx={{
          textAlign: "center",
          fontWeight: 600,
          margin: "2rem 0rem 2rem 0rem",
          fontFamily: "Daikon-regular",
        }}
      >
        Secure Returns with Proven, Established Companies
      </Typography>
      <Box
        sx={{
          margin: "0.5rem 6rem 0.5rem 6rem",
          textAlign: "center",
          fontFamily: "Daikon-regular",
        }}
      >
        <Typography variant="p" sx={{ fontFamily: "Daikon-regular" }}>
        For investors seeking more stability, established businesses offer a proven track record, steady cash flow, and predictable returns. These companies have weathered market challenges and demonstrated their ability to generate sustainable growth over time. At AQT, we offer curated investment opportunities in businesses with strong financial fundamentals, robust market positioning, and low-risk profiles.
        </Typography>
        <Box sx={{ paddingTop: "1rem" }}>
          <Typography variant="p" sx={{ fontFamily: "Daikon-regular" }}>
          Investing in established businesses allows you to balance your portfolio with less volatility, making them ideal for risk-averse investors or those looking to preserve capital while generating income.
          </Typography>
        </Box>
      </Box>

      <div className="commoncardtop">
        <div className="commoncardInside">
          <h1 className="card-heading">Why Established Businesses?</h1>
          <ul className="card-list">
            <li><strong>Lower Risk: </strong> Established businesses have survived the early stages of growth and have proven their ability to deliver steady returns.</li>
            <li><strong>Reliable Cash Flow: </strong>These companies typically generate consistent cash flow from operations, providing investors with a more predictable income stream.</li>
            <li><strong>Proven Business Models: </strong>With years of operational history, these companies offer less uncertainty regarding their market position and long-term sustainability.</li>
            <li><strong>Opportunities for Expansion: </strong> Many established businesses still have room for growth, particularly through geographic expansion, new product lines, or acquisitions.</li>
          </ul>
        </div>
        <div className="commoncardInside">
          <h1 className="card-heading">How We Select Established Businesses</h1>
          <ul className="card-list">
            <li><strong>Financial Performance: </strong>We analyze historical financial data, including revenue growth, profitability, and cash flow stability.</li>
            <li><strong>Market Position: </strong> We assess the company’s market share and competitive advantage within its industry to ensure long-term growth prospects.</li>
            <li><strong>Risk Management: </strong>We evaluate the company’s operational risks, including leadership stability, governance, and regulatory compliance, to minimize investment risk.</li>
          </ul>
        </div>
      </div>
        <Footer/>
        </>
    )
}