import React from "react"
import Navbar from "../../components/navbar/index";
import Footer from "../../components/Footer/Footer";
import projectInvestment from "../../assets/images/projectinvestment.png";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import './Common.css'

export default function ProjectInvestment(){
    return(
        <>
        <Navbar/>
        <Box sx={{ padding: "2rem 0rem 0rem 0rem" }}>
        <img
          style={{
            width: "100%",
            justifyContent: "center",
            margin: "auto",
            display: "block",
            height: "20rem",
          }}
          src={projectInvestment}
          alt="projectInvestment"
        />
      </Box>
      <Typography
        variant="h5"
        sx={{
          textAlign: "center",
          fontWeight: 600,
          margin: "2rem 0rem 2rem 0rem",
          fontFamily: "Daikon-regular",
        }}
      >
        Invest in Large-Scale Projects with Lasting Impact
      </Typography>
      <Box
        sx={{
          margin: "0.5rem 6rem 0.5rem 6rem",
          textAlign: "center",
          fontFamily: "Daikon-regular",
        }}
      >
        <Typography variant="p" sx={{ fontFamily: "Daikon-regular" }}>
        AQT connects investors with large-scale, high-impact projects across industries such as infrastructure, renewable energy, and technology. Project investments not only offer significant financial returns but also contribute to societal and economic development. Whether it’s a green energy initiative or a major infrastructure project, these investments allow you to diversify your portfolio with projects that are both profitable and purpose-driven.
        </Typography>
      </Box>

      <div className="commoncardtop">
        <div className="commoncardInside">
          <h1 className="card-heading">Why Project Investments?</h1>
          <ul className="card-list">
            <li><strong>Long-Term Growth: </strong>Large-scale projects are typically structured for long-term profitability, offering sustained returns over an extended period.</li>
            <li><strong>High Impact: </strong>These investments contribute to societal progress, including infrastructure development, renewable energy solutions, and technological advancements.</li>
            <li><strong>Diversified Portfolio: </strong>Project investments allow you to diversify beyond traditional equity or debt, adding a unique asset class to your portfolio.</li>
            <li><strong>Risk-Adjusted Returns: </strong>While larger in scale, these projects are carefully vetted to balance risk and return, ensuring that you can achieve strong performance over time.</li>
          </ul>
        </div>
        <div className="commoncardInside">
          <h1 className="card-heading">Our Project Portfolio</h1>
          <ul className="card-list">
            <li><strong>Infrastructure: </strong>Invest in the development of critical infrastructure projects that support economic growth and urban development.</li>
            <li><strong>Renewable Energy: </strong>Participate in clean energy projects, including solar, wind, and hydroelectric initiatives, that contribute to a sustainable future.</li>
            <li><strong>Technology Parks: </strong>We offer investment opportunities in large technology parks that drive innovation and job creation across regions.</li>
          </ul>
        </div>
      </div>
        <Footer/>
        </>
    )
}