import React from "react"
import Navbar from "../../components/navbar/index";
import Footer from "../../components/Footer/Footer";
import debentures from "../../assets/images/debenture.jpg";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import './Common.css'

export default function Debentures(){
    return(
        <>
        <Navbar/>
        <Box sx={{ padding: "2rem 0rem 0rem 0rem" }}>
        <img
          style={{
            width: "100%",
            justifyContent: "center",
            margin: "auto",
            display: "block",
            height: "20rem",
          }}
          src={debentures}
          alt="debentures"
        />
      </Box>
      <Typography
        variant="h5"
        sx={{
          textAlign: "center",
          fontWeight: 600,
          margin: "2rem 0rem 2rem 0rem",
          fontFamily: "Daikon-regular",
        }}
      >
        Secure, Fixed-Income Investment with Debentures
      </Typography>
      <Box
        sx={{
          margin: "0.5rem 6rem 0.5rem 6rem",
          textAlign: "center",
          fontFamily: "Daikon-regular",
        }}
      >
        <Typography variant="p" sx={{ fontFamily: "Daikon-regular" }}>
        Debentures are a fixed-income investment that offer stable and predictable returns for investors seeking lower-risk opportunities. AQT provides debentures in solid businesses requiring short-term capital injections for expansion or operational stability. Debentures are ideal for investors looking for secure, interest-bearing investments without the exposure to market volatility seen in equities.
        </Typography>
        <Box sx={{ paddingTop: "1rem" }}>
          <Typography variant="p" sx={{ fontFamily: "Daikon-regular" }}>
          Our debenture offerings give you the chance to invest in businesses with strong fundamentals, ensuring that you receive stable returns while contributing to their growth.
          </Typography>
        </Box>
      </Box>

      <div className="commoncardtop">
        <div className="commoncardInside">
          <h1 className="card-heading">Why Debentures?</h1>
          <ul className="card-list">
            <li><strong>Fixed Returns: </strong>Debentures offer consistent interest payments over a defined period, providing predictable income streams for investors.</li>
            <li><strong>Lower Risk Profile: </strong>Unlike equity investments, debentures are lower risk, making them suitable for conservative investors looking to preserve capital.</li>
            <li><strong>Priority in Payout: </strong>In the event of liquidation, debenture holders are paid before equity investors, further reducing investment risk.</li>
            <li><strong>Flexible Terms: </strong>We offer debentures with varying terms and rates, allowing investors to choose the option that best fits their financial goals.</li>
          </ul>
        </div>
        <div className="commoncardInside">
          <h1 className="card-heading">AQT's Debenture Offerings</h1>
          <ul className="card-list">
            <li><strong>Business Security: </strong>We focus on companies with solid financials and growth prospects that require temporary capital boosts for projects or operational needs.</li>
            <li><strong>Competitive Interest Rates: </strong>Our debenture offerings come with competitive interest rates, designed to provide you with superior returns compared to traditional fixed-income investments.</li>
            <li><strong>Transparent Terms: </strong>We ensure that the terms of each debenture are clear, transparent, and fair to investors, providing peace of mind with every transaction.</li>
          </ul>
        </div>
      </div>
        <Footer/>
        </>
    )
}