// import React from "react";
// import { Card, CardMedia, CardContent, Typography } from "@mui/material";
// import hbf from "../../assets/images/hbf.png";
// import { Link } from "react-router-dom";
// import { useState,useEffect } from "react";
// // import nse from '../../assets/images/i2.jpeg'
// const ShareCard = ({ imageUrl, name, ipoYear,id,onClick,heading,url }) => {

//   return (
//     <div>
//       <Card sx={{ display: "flex",boxShadow:"2px 2px lightgrey", backgroundColor:'#F4F9F9', padding:'7px',width:350,height:130}}>
//         <CardMedia 
//           component="img"
//           sx={{ width: 100,height:50, margin: "1.5rem 0rem 0rem 0rem",objectFit: 'contain' }}
//           image={imageUrl}
//           alt={id}
//         />
//         <CardContent>
//          {/* {url !== null  ?  <Typography variant="h8" component="div">
//             <Link className="mainbodycard1"  target="_blank" href={url}  onClick={onClick} heading={heading}>{name.toUpperCase()}</Link>
//           </Typography> :  <Typography variant="h8" component="div">
//             <Link className="mainbodycard1" to="#"  onClick={onClick} heading={heading}>{name.toUpperCase()}</Link>
//           </Typography>} */}
//            {url !== null  ?  <Typography variant="h8" component="div">
//             <a className="mainbodycard1"  target="_blank" href={url}  onClick={onClick} heading={heading}>{name.toUpperCase()}</a>
//           </Typography> :  <Typography variant="h8" component="div">
//             <Link className="mainbodycard1" to="#"  onClick={onClick} heading={heading}>{name.toUpperCase()}</Link>
//           </Typography>}
          
//           <Typography variant="body2" color="text.secondary" sx={{paddingTop:'4px', fontFamily:'Daikon-Regular'}}>
//             Assumed IPO Year: {ipoYear}
//           </Typography>
//         </CardContent>
//       </Card>
//     </div>
//   );
// };

// export default ShareCard;


import React from "react";
import { Card, CardMedia, CardContent, Typography, Link as MuiLink } from "@mui/material";
import { Link } from "react-router-dom";

const ShareCard = ({ imageUrl, name, ipoYear, id, onClick, heading, url }) => {
  return (
    <Card 
      sx={{ 
        display: "flex", 
        boxShadow: "2px 2px 8px rgba(0, 0, 0, 0.1)", 
        backgroundColor: "#F4F9F9", 
        padding: '10px',
        width: 350, 
        height: 140, 
        paddingRight:'4px',
        borderRadius: '8px', 
        transition: 'transform 0.3s, box-shadow 0.3s', // Smooth transition for the hover effect
        '&:hover': { 
          transform: 'scale(1.02)', // Slightly scale the card on hover
          boxShadow: "4px 4px 12px rgba(0, 0, 0, 0.2)", // Increase the shadow on hover
        },
      }}
    >
      <CardMedia
        component="img"
        sx={{ 
          width: 100, 
          height: 60, 
          margin: "auto 0", 
          objectFit: 'contain' 
        }}
        image={imageUrl}
        alt={id}
      />
      <CardContent sx={{ paddingLeft: '16px', flexGrow: 1 }}>
        {url !== null ? (
          <Typography variant="h6" component="div">
            <MuiLink 
              className="mainbodycard1" 
              href={url} 
              target="_blank" 
              rel="noopener noreferrer" 
              onClick={onClick} 
              sx={{ 
                color: "black", 
                fontSize:'17.5px',
                textDecoration: 'none', 
                '&:hover': { textDecoration: 'underline' }
              }}
            >
              {name.toUpperCase()}
            </MuiLink>
          </Typography>
        ) : (
          <Typography variant="h6" component="div">
            <Link 
              className="mainbodycard1" 
              to="#" 
              onClick={onClick} 
              sx={{ 
                color: "#007bff", 
                textDecoration: 'none', 
                '&:hover': { textDecoration: 'underline' }
              }}
            >
              {name.toUpperCase()}
            </Link>
          </Typography>
        )}
        
        <Typography 
          variant="body2" 
          color="text.secondary" 
          sx={{ paddingTop: '8px', fontFamily: 'Daikon-Regular' }}
        >
          Assumed IPO Year: {ipoYear}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ShareCard;

