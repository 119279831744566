import React from "react"
import Navbar from "../../components/navbar/index";
import Footer from "../../components/Footer/Footer";
import earlybusiness1 from "../../assets/images/earlybusiness1.png";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import './Common.css'

export default function EarlyBusiness(){
    return(
        <>
        <Navbar/>
        <Box sx={{ padding: "2rem 0rem 0rem 0rem" }}>
        <img
          style={{
            width: "100%",
            justifyContent: "center",
            margin: "auto",
            display: "block",
            height: "20rem",
          }}
          src={earlybusiness1}
          alt="business"
        />
      </Box>
      <Typography
        variant="h5"
        sx={{
          textAlign: "center",
          fontWeight: 600,
          margin: "2rem 0rem 2rem 0rem",
          fontFamily: "Daikon-regular",
        }}
      >
        Shape the Future of High-Growth Ventures
      </Typography>
      <Box
        sx={{
          margin: "0.5rem 6rem 0.5rem 6rem",
          textAlign: "center",
          fontFamily: "Daikon-regular",
        }}
      >
        <Typography variant="p" sx={{ fontFamily: "Daikon-regular" }}>
        Early-stage businesses represent the next phase in a company’s development—often beyond the startup phase but not yet mature. These ventures are typically in their growth stage, requiring capital to scale operations, enter new markets, or refine their products. Investing in early-stage companies gives you the opportunity to be a critical part of a company’s upward trajectory.
        </Typography>
        <Box sx={{ paddingTop: "1rem" }}>
          <Typography variant="p" sx={{ fontFamily: "Daikon-regular" }}>
          At AQT, we identify businesses with strong fundamentals and growth potential, allowing investors to get in on the ground floor of what could be the next industry leader.
          </Typography>
        </Box>
      </Box>

      <div className="commoncardtop">
        <div className="commoncardInside">
          <h1 className="card-heading">Why Early-Stage Businesses?</h1>
          <ul className="card-list">
            <li><strong>Growth Phase: </strong>  Early-stage businesses are past the initial risk phase but still have significant growth potential, offering a balanced risk-reward profile.</li>
            <li><strong>Influence in Strategy: </strong>Investors often have a say in the company’s strategic direction, allowing them to shape how the business scales.</li>
            <li><strong>High-Return Potential: </strong>While riskier than investing in established businesses, early-stage companies offer the chance for substantial returns as they grow.</li>
            <li><strong>Sector-Focused Opportunities: </strong> AQT connects investors with early-stage businesses across multiple sectors, including fintech, e-commerce, healthcare, and renewable energy.</li>
          </ul>
        </div>
        <div className="commoncardInside">
          <h1 className="card-heading">Our Approach to Early-Stage Investments</h1>
          <ul className="card-list">
            <li><strong>Growth Strategy: </strong>We assess each business’s potential for scaling and market penetration, focusing on those with clear expansion strategies.</li>
            <li><strong>Management Team: </strong>Leadership is key. We look for founders and teams with a track record of success and the vision to lead the company to the next level.</li>
            <li><strong>Capital Requirements: </strong>We ensure the business has the right capital structure to scale effectively and manage operational growth without overextending resources.</li>
          </ul>
        </div>
      </div>
        <Footer/>
        </>
    )
}