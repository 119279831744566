import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/navbar/index.jsx";
import Button from "@mui/material/Button";
import "./MainBody.css";
import speaker from "./MainPageContent/speaker.png";
import Typography from "@mui/material/Typography";
import BodyInside from "./MainPageContent/BodyInside";
import BodyTwo from "./MainPageContent/BodyTwo";
import EndPage from "./MainPageContent/EndPage";
import TablePage from "./MainPageContent/Table";
import Testimonial from "./MainPageContent/Testimonial";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect } from "react";
import Container from "@mui/material/Container";
import ShareCard from "../../components/cards/ShareCard.jsx";
import Grid from "@mui/material/Unstable_Grid2";
import main from "../../assets/images/main-trade.png";
import bulb from "../../assets/images/bull-bulb-line.png";
import { useState } from "react";
import { api, imageApi } from "../../components/Api/Api.jsx";
import { redirect } from "../../components/Api/Api.jsx";
import { dataShow } from "./DataMain.js";
import globe from "../../assets/images/globe.jpg";
import aqt from "../../assets/images/aqt.png";
import { textAlign } from "@mui/system";
import CompanyCard from "../../components/cards/CompanyCard.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

const cardPerPage = 6;
export default function MainBody({ idpoly, headings, images, url }) {
  const [data, setData] = useState(null);
  const [dataOnPage, setDataOnPage] = useState(null);
  const [search, setSearch] = useState("");
  const [pages, setPages] = useState(1);
  const lastIndex = cardPerPage * pages;
  const firstIndex = lastIndex - cardPerPage;

  // const styles = {
  //     containermain: {
  //         width: '100%',
  //         height: '100vh',
  //         backgroundImage: `url(${globe})`,
  //         backgroundSize: 'cover',
  //         backgroundPosition: 'center',
  //         // filter: 'blur(1px)',
  //     },
  // };

  //   const styles = {
  //     wrapper: {
  //       position: "relative",
  //       width: "100%",
  //       height: "100vh",
  //       overflow: "hidden",
  //     },
  //     blurredBackground: {
  //       position: "absolute",
  //       top: 25,
  //       left: 0,
  //       width: "100%",
  //       height: "80vh",
  //       backgroundImage: `url(${globe})`,
  //       backgroundSize: "cover",
  //       backgroundPosition: "center",
  //       filter: "blur(3px)",
  //       zIndex: 1,
  //     },
  //     content: {
  //       position: "relative",
  //       zIndex: 2,
  //       padding: "20px",
  //       color: "#fff",
  //       // textAlign:'center'
  //       display: "flex",
  //       justifyContent: "center",
  //       textAlign: "center",
  //     },
  //   };

  const FetchData = async () => {
    try {
      await fetch(api)
        .then((res) => res.json())
        .then((res) => setData(res.detail.rows.slice(-6)));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    FetchData();
  }, []);

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const filter =
    data !== null
      ? data.filter((f) => f.name.toLowerCase().includes(search.toLowerCase()))
      : [];

  console.log("filter in main", filter);

  // const redir = function redirectToPloy(id){
  //     navigate('/polymatech',{state:id})
  // }

  return (
    <>
      <Navbar />

      {/* Main Banner */}

      <div>
        <div className="main-banner">
        
          <h1>Managing & Maximizing Private Equity </h1>
          <p >
            Welcome to AQT Direct Limited, your gateway to managing and
            maximizing private equity investments.
            <br />
            We specialize in building fortunes and creating legacies for our
            clients, while safeguarding them from valuation bubbles.{" "}
          </p>
        </div>
      </div>

      {/* <div style={styles.wrapper}>
        <div style={styles.blurredBackground}></div>
        <div style={styles.content}>
          <div>
            <h1 className="head">Managing & Maximizing Private Equity </h1>
            <p className="para">
              Welcome to AQT Direct Limited, your gateway to managing and
              maximizing private equity investments.
              <br />
              We specialize in building fortunes and creating legacies for our
              clients, while safeguarding them from valuation bubbles.{" "}
            </p>
    
          </div>
        </div>
      </div> */}

    {/* Main Banner End */}

      {/* <div className="SearchbarMain"> */}
      <Container>
        {filter.length > 0 && (
          <div className="searchbar">
            {/* <img className="image" src={search} alt="searching"></img> */}
            <input
              className="inputmain"
              type="text"
              placeholder="Search..."
              value={search}
              onChange={handleChange}
            />
            <SearchIcon sx={{ margin: "0.7rem 0rem 0rem -2rem"}} className="search-icon" />

            {/* <button className='buttonNav' type='button'>Search</button> */}
          </div>
        )}
        <div className="boxes">
          <div className="boxes">
            <Grid container spacing={2}>
              {filter !== null
                ? filter.map((company) => (
                    <Grid item="true" xs={4} key={company.id}   >
                      {/* <ShareCard
                        imageUrl={`${imageApi}${company.cmpImg}`}
                        onClick={() => {
                          headings(company.name);
                          idpoly(company.id);
                          images(`${imageApi}${company.cmpImg}`);
                        }}
                        
                        name={company.name}
                        ipoYear={company.ipoYear}
                        url={company.websiteUrl}
                      /> */}
                     <CompanyCard
                       imageUrl={`${imageApi}${company.cmpImg}`}
                       onClick={() => {
                         headings(company.name);
                         idpoly(company.id);
                         images(`${imageApi}${company.cmpImg}`);
                       }}
                       
                      
                     name={company.name}
                     id={company.id}
                     ipoYear={company.ipoYear}
                     url={company.websiteUrl}
                     about= {company.about}
                     address={company.address}
                     directorName={company.directorName}
                     registrationDate={company.registrationDate}
                     mail={company.mail}
                     city={company.city}
                     totalShares={company.totalShares}
                     perShareValue={company.perShareValue}
                     overview={company.overview}
                     productAndServices={company.productAndServices}
                     />
                    </Grid>
                  ))
                : ""}
            </Grid>
          </div>
        </div>

        {filter.length > 0 && (
          <div
            style={{
              textAlign: "end",
              paddingTop: "20px",
              paddingBottom: "25px",
            }}
          >
            <Button
              variant="contained"
              color="success"
             
              className="view-more"
            >
              <Link
                style={{ textDecoration: "none", color: "black" }}
                to="/companylist"
              >

               View More &nbsp;
                <FontAwesomeIcon icon={faEye} />
              </Link>
            </Button>
          </div>
        )}
      </Container>

      <BodyInside />

      <div style={{ padding: "16px", paddingBottom: "32px" }}>
        <div style={{ textAlign: "center", fontFamily: "Daikon-regular" }}>
          <h1>Process To Buy Private Equity</h1>
          <p>Recommended For Those Interested In Dealing With Private Equity</p>
        </div>
        <div className="square">
          <div className="left-line"></div>
          <div className="dot top-dot"></div>
          <div className="dot bottom-dot"></div>
          <div className="number number-top" style={{ fontWeight: "bold" }}>
            01
          </div>
          {/* <!-- <div className="number number-bottom">6</div> --> */}
          <div className="outer-circle"></div>
          <div className="left-text" style={{ fontFamily: "Daikon-regular" }}>
            At AQT Direct, we conduct thorough research and audits to provide
            our clients with valuable insights into potential investment
            opportunities. Our meticulous approach helps identify risks and
            opportunities, allowing investors to make informed decisions.
          </div>
          <div className="center-text" style={{ fontFamily: "Daikon-regular" }}>
            Research & Audit
          </div>
        </div>
        <br />

        <div className="square2">
          <div className="left-line2"></div>
          <div className="dot2 top-dot2"></div>
          <div className="dot2 bottom-dot2"></div>
          <div className="number2 number-top2" style={{ fontWeight: "bold" }}>
            02
          </div>
          <div className="outer-circle2"></div>
          <div className="left-text2" style={{ fontFamily: "Daikon-regular" }}>
            We ensure that our clients comply with all regulatory requirements
            and best practices when structuring equity deals. Our expertise in
            deal compliance minimizes legal and financial risks, safeguarding
            our clients' investments.
          </div>
          <div
            className="center-text2"
            style={{ fontFamily: "Daikon-regular" }}
          >
            Equity Deal Compliances
          </div>
        </div>

        <br />
        <div className="square">
          <div className="left-line"></div>
          <div className="dot top-dot"></div>
          <div className="dot bottom-dot"></div>
          <div className="number number-top" style={{ fontWeight: "bold" }}>
            03
          </div>
          {/* <div className="number number-bottom">6</div>  */}
          <div className="outer-circle"></div>
          <div className="left-text" style={{ fontFamily: "Daikon-regular" }}>
            {" "}
            AQT Direct specializes in creating value for our clients through
            strategic investment strategies and operational improvements. We
            work closely with management teams to unlock growth potential and
            enhance shareholder value.
          </div>
          <div className="center-text" style={{ fontFamily: "Daikon-regular" }}>
            Value Creation
          </div>
        </div>

        <br />
        <div className="square2">
          <div className="left-line2"></div>
          <div className="dot2 top-dot2"></div>
          <div className="dot2 bottom-dot2"></div>
          <div className="number2 number-top2" style={{ fontWeight: "bold" }}>
            04
          </div>
          <div className="outer-circle2"></div>
          <div className="left-text2" style={{ fontFamily: "Daikon-regular" }}>
            Planning an exit strategy is crucial for maximizing returns on
            investments. AQT Direct assists clients in developing and executing
            exit plans that align with their financial objectives and market
            conditions.
          </div>
          <div
            className="center-text2"
            style={{ fontFamily: "Daikon-regular" }}
          >
            Exit Strategist
          </div>
        </div>
      </div>
      <Testimonial />
      <EndPage />
      <Footer />
    </>
  );
}
