import React from "react";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/navbar/index.jsx";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CardActionArea from "@mui/material/CardActionArea";
import "./FundRaise.css";
import fundraise from "../../assets/images/fundraise.jpg";
import loan from "../../assets/images/loan.jpg";
import investor from "../../assets/images/investor.webp"

export default function PEInvestor() {
  return (
    <>
      <Navbar />
      <div className="mediacover1">
        <div className="mediacover11">
          <h1>Creating an Investment Ecosystem</h1>
          <p>
          At AQT, we provide a holistic ecosystem that supports every stage of the investment process. We offer a unique platform where investors can access a diverse range of opportunities—from seed-stage startups to mature businesses and specialized project investments. Our focus is on curating investments that not only offer high returns but are also strategically aligned with the future of industries.<br/>
          We are dedicated to educating and guiding our investors through the intricacies of private equity. Every decision we make is backed by deep research, market insights, and a firm understanding of global trends.
          </p>
        </div>
        <img src={investor} style={{ width: "35%",paddingTop:"1rem" }} alt="articalimage" />
      </div>
      <div className="buysellbox">
        <h1>Investment Opportunities</h1>
        <p>
          Here’s a look at the wide range of investment options AQT provides
        </p>
      </div>
      <Container>
        <div className="peinvesthead">
          <div className="peinvestcardtop">
            <Card sx={{ maxWidth: 345 }}>
              <CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Startups
                  </Typography>
                  <Typography variant="body2" sx={{ color: "text.secondary" }}>
                    We connect you to innovative, high-growth ventures in
                    various sectors such as technology, healthcare, and green
                    energy. Startups offer the potential for exponential growth
                    and are ideal for investors looking for high-risk,
                    high-reward opportunities.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </div>
          <div className="peinvestcardtop">
            <Card sx={{ maxWidth: 345 }}>
              <CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Established Businesses
                  </Typography>
                  <Typography variant="body2" sx={{ color: "text.secondary" }}>
                    For those seeking more stability, we offer investment
                    opportunities in established businesses with proven
                    financial performance. These companies come with lower risk
                    profiles and steady cash flow, making them attractive to
                    risk-averse investors.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </div>
          <div className="peinvestcardtop">
            <Card sx={{ maxWidth: 345 }}>
              <CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Early-Stage Businesses
                  </Typography>
                  <Typography variant="body2" sx={{ color: "text.secondary" }}>
                    Early-stage ventures are in their growth phase, requiring
                    capital to scale operations. We identify businesses with
                    strong fundamentals and growth trajectories, providing
                    investors with opportunities to shape the future of these
                    companies.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </div>
          <div className="peinvestcardtop">
            <Card sx={{ maxWidth: 345 }}>
              <CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Debentures
                  </Typography>
                  <Typography variant="body2" sx={{ color: "text.secondary" }}>
                    Offering secure, fixed-income returns, debentures are
                    perfect for those looking for stable investments. Our
                    debenture offerings give you the chance to invest in
                    businesses that are solid yet need temporary capital boosts
                    for expansion.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </div>
          <div className="peinvestcardtop">
            <Card sx={{ maxWidth: 345 }}>
              <CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Project Investments
                  </Typography>
                  <Typography variant="body2" sx={{ color: "text.secondary" }}>
                    Large-scale projects like infrastructure, technology parks,
                    and renewable energy projects are part of our portfolio. We
                    allow investors to partake in ventures that not only promise
                    financial returns but also contribute to economic and
                    societal development.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </div>
        </div>
      </Container>






      <div className="buysellbox">
        <h1>The AQT Ecosystem for Investors</h1>
        <p>
        Our platform does more than facilitate investments. AQT offers an end-to-end service that takes care of every aspect of the investment process
        </p>
      </div>
      <Container>
        <div className="peinvesthead">
          <div className="peinvestcardtop">
            <Card sx={{ maxWidth: 345 }}>
              <CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                  Product Identification
                  </Typography>
                  <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  We help you choose the right businesses to invest in based on your risk tolerance and financial goals.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </div>
          <div className="peinvestcardtop">
            <Card sx={{ maxWidth: 345 }}>
              <CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                  Share Transfer and Exit Strategies
                  </Typography>
                  <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  Once your investment matures, we guide you through the exit process, ensuring you gain the maximum possible return.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </div>
          <div className="peinvestcardtop">
            <Card sx={{ maxWidth: 345 }}>
              <CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                  Compliance and Regulatory Support
                  </Typography>
                  <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  We handle all the legal and compliance aspects of your investments, minimizing your administrative burden while maximizing efficiency.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
