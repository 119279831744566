import React from "react";
import Navbar from "../../components/navbar/index";
import Footer from "../../components/Footer/Footer";
import startup1 from "../../assets/images/startup1.jpeg";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import './Common.css'

export default function StartUp() {
  return (
    <>
      <Navbar />
      <Box sx={{ padding: "2rem 0rem 0rem 0rem" }}>
        <img
          style={{
            width: "100%",
            justifyContent: "center",
            margin: "auto",
            display: "block",
            height: "20rem",
          }}
          src={startup1}
          alt="aboutus"
        />
      </Box>
      <Typography
        variant="h5"
        sx={{
          textAlign: "center",
          fontWeight: 600,
          margin: "2rem 0rem 2rem 0rem",
          fontFamily: "Daikon-regular",
        }}
      >
        Fuel Innovation Through Startup Investments
      </Typography>
      <Box
        sx={{
          margin: "0.5rem 6rem 0.5rem 6rem",
          textAlign: "center",
          fontFamily: "Daikon-regular",
        }}
      >
        <Typography variant="p" sx={{ fontFamily: "Daikon-regular" }}>
          Investing in startups is not just about capital; it’s about believing
          in the next big idea and helping shape the future. At AQT, we connect
          you with innovative, high-growth ventures across sectors like
          technology, healthcare, and green energy. These industries are at the
          forefront of transformation, creating exponential growth opportunities
          for early investors.
        </Typography>
        <Box sx={{ paddingTop: "1rem" }}>
          <Typography variant="p" sx={{ fontFamily: "Daikon-regular" }}>
            Startups typically require funding to accelerate product
            development, expand operations, and enter new markets. As an
            investor, you will be part of a dynamic ecosystem that supports
            these growth objectives. We offer you access to ventures with robust
            business models, experienced leadership, and a clear path to
            scalability.
          </Typography>
        </Box>
      </Box>

      <div className="commoncardtop">
        <div className="commoncardInside">
          <h1 className="card-heading">Why Choose Startups?</h1>
          <ul className="card-list">
            <li><strong>High Growth Potential: </strong> Early-stage startups have the capacity for rapid expansion, offering the potential for returns that far outpace traditional investments.</li>
            <li><strong>Diverse Opportunities: </strong>With access to innovative sectors such as AI, clean energy, biotech, and more, you can diversify your portfolio into industries driving tomorrow’s economy.</li>
            <li><strong>Influence and Impact: </strong>As an investor in startups, you often have a direct impact on strategic decisions. Your support can help steer a company towards success.</li>
            <li><strong>Exclusive Opportunities: </strong>AQT vets startups rigorously, ensuring that you are exposed to only the most promising ventures in the market.</li>
          </ul>
        </div>
        <div className="commoncardInside">
          <h1 className="card-heading">Our Process for Startup Investments</h1>
          <ul className="card-list">
            <li><strong>Market Viability: </strong>We assess the target market, its size, and the startup's position within the industry to ensure scalability.</li>
            <li><strong>Team Expertise: </strong>Strong leadership is critical. We focus on startups led by experienced and visionary founders who are committed to growth.</li>
            <li><strong>Financial Model: </strong>We perform a deep dive into the startup’s financials to ensure the business model is sustainable and aligned with industry benchmarks.</li>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
}
