// import React from "react";
// import Navbar from "../../components/navbar/index";
// import Footer from "../../components/Footer/Footer";
// import aboutus from "../../assets/images/aboutus.jpg";
// import Container from "@mui/material/Container";
// import mission from "../../assets/images/mission.jpg";
// import vision from "../../assets/images/vision1.png";
// import ceo from "../../assets/images/ceo.png";
// import dummy from "../../assets/images/dummyuser.png";
// // import aboutus1 from "../../assets/images/aboutus1.jpg";
// import aboutus2 from "../../assets/images/aboutus2.jpeg";
// import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";
// import { useLocation } from "react-router-dom";
// import { useEffect } from "react";

// export default function AboutUs() {
//   const { pathname } = useLocation();
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [pathname]);

//   return (
//     <>
//       <Navbar />

//       <Box sx={{ padding: "2rem 0rem 0rem 0rem" }}>
//         <img
//           style={{
//             width: "100%",
//             justifyContent: "center",
//             margin: "auto",
//             display: "block",
//             height: "20rem",
//           }}
//           src={aboutus2}
//           alt="aboutus"
//         />
//       </Box>
//       <Container>
//       <Typography
//         variant="h5"
//         sx={{
//           textAlign: "center",
//           fontWeight: 600,
//           fontSize:'2rem',
//           margin: "3rem 0rem 2rem 0rem",
//           fontFamily: "Daikon-regular",
//         }}
//       >
//         AQT Direct Limited is a leading consultancy firm <br/> specializing in private
//         equity investments.
//       </Typography>
//       <Box
//         sx={{
//           margin: "0.5rem 6rem 0.5rem 6rem",
//           textAlign: "start",
//           fontSize:'1.1rem',
//           lineHeight:'1.4',
//           fontFamily: "Daikon-regular",
//         }}
//       >
//         <Typography variant="p" sx={{ fontFamily: "Daikon-regular" }}>
//           At AQT, we're not just a Private Equity Consultancy firm; we're
//           architects of opportunity and architects of change. With a deep-rooted
//           commitment to excellence and innovation, we specialize in reshaping
//           the private equity landscape through strategic insights, dynamic
//           partnerships, and sustainable growth initiatives.
//         </Typography>
//         <Box sx={{paddingTop:'1rem' }}>
//         <Typography variant="p" sx={{ fontFamily: "Daikon-regular"}}>
//           Driven
//           by a passion for progress and a vision for a brighter tomorrow, we're
//           dedicated to fueling the evolution of India's business ecosystem. From
//           pioneering investment models to fostering impactful change, we're
//           shaping the future of private equity in India and beyond. With a focus on integrity, collaboration, and unwavering dedication,
//           we're not just shaping businesses; we're shaping legacies.
//           </Typography>
//           </Box>

//           <Box sx={{paddingTop:'4rem', paddingBottom:'2rem',textAlign:'center' }}>
//           <Typography variant="h5" sx={{ fontFamily: "Daikon-regular",fontWeight:600,  fontSize:'2rem', }}>
//           Welcome to AQT, where opportunity meets transformation, <br/> and the
//           possibilities are limitless.</Typography></Box>
//       </Box>

//       <div className="commoncardtop1">
//         <div className="commoncardInside">
//           <img src={mission} alt="Card 1" className="card-image" />
//           <h1 className="card-heading1">Our Mission</h1>
//           <ul className="card-list1">
//             <li>AQT Direct Limited is on a mission to enhance financial literacy among the Indian population. We aim to enlighten individuals about the short-term and long-term benefits of smart investments, nurturing them into angel investors. By funding businesses in need of growth capital, we aspire to create generational wealth and foster economic prosperity.</li>

//           </ul>
//         </div>
//         <div className="commoncardInside">
//           <img src={vision} alt="Card 2" className="card-image" />
//           <h1 className="card-heading1">Our Vision</h1>
//           <ul className="card-list1">
//             <li>Our vision is to cultivate a supportive ecosystem where trust and capital flow freely, empowering people to grow together. We envision a thriving India, with businesses expanding globally, bolstering the economy. This transformation can only occur by fostering a collaborative ecosystem where awareness and support pave the way for sustainable growth and prosperity.</li>
//           </ul>
//         </div>
//       </div>

//       <div className="commoncardtop1">
//         <div className="commoncardInside">
//           <img src={ceo} alt="Card 1" className="card-image" />
//           <h1 className="card-heading1">CEO Message</h1>
//           <ul className="card-list1">
//             <li>By backing and nurturing these small enterprises, we have the power
//             to generate employment, encourage creativity, and stimulate economic
//             advancement within our local areas. Additionally, this endeavour
//             represents a significant stride towards India's ambition of ranking
//             among the globe's top three economies. As citizens, it's incumbent
//             upon us to contribute to our nation's progress and prosperity.</li>

//           </ul>
//         </div>
//         <div className="commoncardInside">
//           <img src={dummy} alt="Card 2" className="card-image" />
//           <h1 className="card-heading1"> CBO Message</h1>
//           <ul className="card-list1">
//             <li> I am dedicated to transforming the private equity landscape through
//             the cultivation of strategic alliances, inventive investment
//             frameworks, and sustainable development endeavours. My vision is to
//             instigate meaningful progress, catalyzing the metamorphosis of
//             India's business terrain, and positioning AQT as a driving force for
//             beneficial economic change.</li>
//           </ul>
//         </div>
//       </div>
//       </Container>
//       <Footer />
//     </>
//   );
// }



import React, { useEffect } from "react";
import Navbar from "../../components/navbar/index";
import Footer from "../../components/Footer/Footer";
import {
  Container,
  Box,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import aboutus2 from "../../assets/images/aboutus2.jpeg";
import mission from "../../assets/images/mission.jpg";
import vision from "../../assets/images/vision1.png";
import ceo from "../../assets/images/ceo.png";
import dummy from "../../assets/images/dummyuser.png";

export default function AboutUs() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Navbar />

      {/* Hero Section */}
      <Box
        sx={{
          width: "100%",
          height: "400px",
          overflow: "hidden",
          marginBottom: "2rem",
        }}
      >
        <img
          src={aboutus2}
          alt="About Us"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </Box>

      {/* Main Container */}
      <Container sx={{ paddingY: "3rem" }}>
        <Typography
          variant="h4"
          align="center"
          fontWeight="bold"
          sx={{
            marginBottom: "2rem",
            fontFamily: "Daikon-regular",
            letterSpacing: "0.5px",
          }}
        >
          AQT Direct Limited: Leading Private Equity Investment Consultancy
        </Typography>

        {/* About Us Description */}
        <Typography
          variant="body1"
          align="center"
          sx={{
            maxWidth: "800px",
            margin: "0 auto",
            lineHeight: "1.8",
            fontSize: "1.1rem",
            fontFamily: "Daikon-regular",
            textAlign: "start",
          }}
        >
          At AQT, we're not just a Private Equity Consultancy firm; we're
          architects of opportunity and architects of change. With a deep-rooted
          commitment to excellence and innovation, we specialize in reshaping
          the private equity landscape through strategic insights, dynamic
          partnerships, and sustainable growth initiatives.
        </Typography>

        <Typography
          variant="body1"
          align="center"
          sx={{
            maxWidth: "800px",
            margin: "1rem auto",
            lineHeight: "1.8",
            fontSize: "1.1rem",
            fontFamily: "Daikon-regular",
            textAlign: "start",
          }}
        >
          Driven by a passion for progress and a vision for a brighter tomorrow,
          we're dedicated to fueling the evolution of India's business
          ecosystem. From pioneering investment models to fostering impactful
          change, we're shaping the future of private equity in India and
          beyond. With a focus on integrity, collaboration, and unwavering
          dedication, we're not just shaping businesses; we're shaping legacies.
        </Typography>

        <Typography
          variant="h5"
          fontWeight="bold"
          align="center"
          sx={{
            marginTop: "3rem",
            marginBottom: "2rem",
            fontFamily: "Daikon-regular",
          }}
        >
          Our Mission & Vision
        </Typography>

        {/* Mission and Vision Cards */}
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} sm={6}>
            <Card sx={{ boxShadow: 3 }}>
              <CardMedia
                component="img"
                height="200"
                image={mission}
                alt="Mission"
              />
              <CardContent>
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  Our Mission
                </Typography>
                <Typography variant="body2" sx={{ lineHeight: "1.6" }}>
                  AQT Direct Limited is committed to enhancing financial
                  literacy across India. We strive to educate individuals about
                  smart investments, nurturing them into angel investors for the
                  future of a prosperous economy.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Card sx={{ boxShadow: 3 }}>
              <CardMedia
                component="img"
                height="200"
                image={vision}
                alt="Vision"
              />
              <CardContent>
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  Our Vision
                </Typography>
                <Typography variant="body2" sx={{ lineHeight: "1.6" }}>
                  We envision a world where trust and capital flow freely,
                  empowering communities and businesses to grow together. AQT
                  aims to elevate India to a leading global economy through
                  innovative investments.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* CEO and CBO Messages */}
        <Typography
          variant="h5"
          fontWeight="bold"
          align="center"
          sx={{
            marginTop: "4rem",
            marginBottom: "2rem",
            fontFamily: "Daikon-regular",
          }}
        >
          Leadership Messages
        </Typography>

        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} sm={6}>
            <Card sx={{ boxShadow: 3 }}>
              <CardMedia component="img" height="200" image={ceo} alt="CEO" />
              <CardContent>
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  Message from the CEO
                </Typography>
                <Typography variant="body2" sx={{ lineHeight: "1.6" }}>
                  By nurturing small enterprises, we generate employment, spark
                  creativity, and drive economic growth in local communities.
                  This is a vital step in realizing India's ambition of becoming
                  a global economic leader.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Card sx={{ boxShadow: 3 }}>
              <CardMedia component="img" height="200" image={dummy} alt="CBO" />
              <CardContent>
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  Message from the CBO
                </Typography>
                <Typography variant="body2" sx={{ lineHeight: "1.6" }}>
                  At AQT, I am committed to driving the future of private equity
                  through strategic partnerships, innovative investment
                  frameworks, and fostering sustainable development initiatives
                  for positive global change.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>

      <Footer />
    </>
  );
}
