import React from "react";
import "./navbar.css";
import search from "../../assets/images/search.jpg";
import { Link, Outlet } from "react-router-dom";
import aqt from "../../assets/images/aqt.png";
import Button from '@mui/material/Button';
import {redirect} from "../Api/Api"

export default function Another() {
  return (
    <>
      <nav className="nav">
        <div className="tophalf">
          <Link to="/">
            <img
              style={{
                width: "8em",
                padding: "1em 0em 0em 1em",
                // margin: "1em 0em 0em 1em",
              }}
              src={aqt}
              alt="something"
            />
          </Link>

          <ul className="bottomhalf">
            <li className="list">
              <Link className="anc" to="/">
                Home
              </Link>
            </li>
            <li className="list">
              <Link className="anc" to="/about">
                About Us
              </Link>
            </li>
            {/* <li className="list">
              <Link className="anc" to="/limited-partnerships">
                Limited Partnerships
              </Link>
            </li> */}
            {/* <li className='list'><a className='anc' href='/drhp'>DRHP-FILED</a></li> */}
            {/* <li className="list">
              <Link className="anc" to="/business-opportunities">
                Business Opportunities
              </Link>
            </li> */}
            {/* <li className='list'><a className='anc' href='/screener'>Screener</a></li> */}
            {/* <li className="list">
              <Link className="anc" to="/insights">
                Insights
              </Link>
            </li> */}
            <li className="list">
              <Link className="anc" to="/peinvestor">
                PE Investor
              </Link>
              <div className="dropdown">
                <Link className="dropdown-item" to="/startUp">Startups</Link>
                <Link className="dropdown-item" to="/business">Businesses</Link>
                <Link className="dropdown-item" to="/earlyBusiness">Early Stage Business</Link>
                <Link className="dropdown-item" to="/debentures">Debentures</Link>
                <Link className="dropdown-item" to="/projectInvestment">Project Investment</Link>
              </div>
            </li>
            <li className="list">
              <Link className="anc" to="/fundraise">
                Fund Raising
              </Link>
            </li>
            <li className="list">
              <Link className="anc" to="/smeloans">
                SME Loans
              </Link>
            </li>
            <li className="list">
              <Link className="anc" to="/buysell">
              Business For Sell
              </Link>
            </li>
            {/* <li className='list'><a className='anc' href='/raisefunding'>Raise Funding</a></li> */}
            <li className="list">
              <Link className="anc" to="/contactus">
                Contact Us
              </Link>
            </li>
          </ul>

          <div className="lowerhalf">
            <Link to={redirect}>
            <Button variant="contained" className="login-btn"  style={{backgroundColor:'#35A848'}}>
              Login
            </Button></Link>
          </div>
        </div>
      </nav>
      {/* <div className="linenav">
    <hr/>
    </div> */}
    </>
  );
}
 